import { ILanguageHelper, normalLanguageHelper } from "@helper/static-language-helper";
import { NextPage } from "next";
import { useTranslation } from "next-i18next";
import React from "react";

export async function getStaticProps({ locale }: ILanguageHelper) {
    return normalLanguageHelper({ locale });
}

const IndexPage: NextPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="glitchwrap mx-auto h-screen pt-40">
                <div className="flex items-center justify-center text-center text-white">
                    <p className="glitch">
                        <span aria-hidden="true">404</span>
                        404
                        <span aria-hidden="true">404</span>
                    </p>
                </div>
                <div className="text-center text-white">{t("generic.not_found")}</div>
            </div>
        </>
    );
};

export default IndexPage;
